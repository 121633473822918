@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Poiret+One&display=swap');

* {
  box-sizing: border-box;
}

.dino-footer {
  padding: 96px 120px;

  /*&-content {*/

  /* }*/
}

.dino-footer-container {
  &__own_logo {
    display: flex;
    align-items: center;
  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 120px;
  background: #F3F5F7;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 120px;
  background: #F3F5F7;
}

.base-container {
  background: #F3F5F7;
}

@media screen and (max-width: 700px) {
  .dino-footer {
    padding: 15px;
  }
  .login-container {
    padding: 15px;
  }
}


.login-card {
  background: #fff;
  padding: 48px;
  border-radius: 16px;
}

.menu-button {
  border-radius: 16px !important;
  padding-bottom: 16px !important;
  padding-top: 16px !important;
  padding-left: 16px !important;

  &__icon {
    color: #fff !important;
  }

  &__text {
    color: #fff !important;
    text-transform: capitalize !important;
  }
}

.menu-item {
  border-radius: 16px;
}

.main {
  padding: 64px;
  overflow-x: hidden;
}

@media screen and (max-width: 700px) {
  .main {
    padding: 10px;
    padding-top: 30px;
  }
}

.web-main {
  padding: 64px;
}

.dashboard-card {
  padding: 32px;
  border-radius: 16px;
  background: white;
}

.white-card {
  padding: 32px;
  border-radius: 16px;
  background: white;
  margin-bottom: 20px;
}

.white-card-sp {
  padding: 32px;
  border-radius: 16px;
  background: white;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .white-card-sp {
    padding: 2px;
  }
  .table-heading {
    font-size: 70% !important;
  }

}

.reservation-name {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.reservation-accepted {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #5EB885;
}

.reservation-waiting {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #ED8802;
}

.active-radio {
  background: #EFF8F3;
  border: 1px solid #9ED4B6;
  border-radius: 16px;
}

.active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #4CAF50 solid 3px
}

.active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #4CAF50 solid 3px
}

.not-active-color {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 16px;
  outline: #F3F5F7 solid 3px
}

.reservation-detail-table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.bank-card {
  padding: 20px 10px;
  border-radius: 10px;
  border: 1px solid #2a3746;
  background: #606e8c;
}

.apartment-card {
  display: flex;
  width: 100%;
  //height: 500px;
  box-shadow: rgba(0, 0, 0, .3) 2px 2px 2px;
  border-radius: 5px;
  overflow: hidden;


  &-left-column {
    width: 50%;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    transition: background-size ease-in-out .6s !important;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 70% 0);
    //&:hover {
    //  background-size: 110% 110% !important;
    //}
  }

  &-right-column {
    width: 50%;
    padding: 20px;
  }
}

@media screen and (max-width: 820px) {
  .apartment-card {
    flex-direction: column;

    &-left-column {
      width: 100%;
      height: 250px;
      clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    &-right-column {
      width: 100%;
      padding: 20px;
    }
  }

}

.about-us-img {
  width: 100%;
  background-size: cover !important;
  height: 300px;
  background-position: 50% 50% !important;
}

.apartment-selector {
  height: 100vh;
  display: flex;

  &-box {
    width: 50%;
    background-size: cover !important;
    background-position: 50% 50% !important;
    padding: 20px;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
    cursor: pointer;
  }

}

@media screen and (max-width: 900px) {
  .apartment-selector {
    height: 100vh;
    display: flex;
    flex-direction: column;

    &-box {
      width: 100%;
      height: 400px;
    }
  }
}

.transparent {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.nav-button {
  padding: 10px;
}

.apartment-selector-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  filter: brightness(70%);
  transition: all .3s;

  &__infos {
    padding: 10px;
    background: #e4d4c8;
    color: white !important;
  }
}

.apartment-selector-box:hover {
  filter: brightness(100%);
}